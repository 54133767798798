<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantContracts(true)" />
                </div>
            </div>
            <el-table :data="plantContracts.data" class="mt-2">
                <el-table-column prop="year" label="年份" sortable width="80" />
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="farmerName" label="种植户" sortable width="160" />
                <el-table-column prop="warrantorNames" label="担保人" sortable width="160" />
                <el-table-column prop="totalArea" label="总面积（亩）" sortable width="160" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="200">
                    <template #default="scope">
                        <el-button type="text" @click="showToDosDialog(scope.row)">履约</el-button>
                        <el-button type="text" @click="showSignPlantContractOfflineDialog(scope.row)">线下签约</el-button>
                        <el-button type="text" @click="showSignPlantContractOnlineDialog(scope.row)">线上签约</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="toDosDialogVisible" title="履约" :close-on-click-modal="false" width="960px"
            @closed="loadPlantContracts">
            <to-dos v-if="toDosDialogVisible" :plant-contract-id="plantContract.id" />
        </el-dialog>

        <el-dialog :visible.sync="signPlantContractOfflineDialogVisible" title="线下签约" :close-on-click-modal="false"
            width="800px">
            <div style="margin: 0 -16px;">
                <div class="form-group-title">履约</div>
                <div class="form-group">
                    <el-table :data="toDos" border>
                        <el-table-column prop="title" label="履约事件" sortable />
                        <el-table-column prop="reminderTime" label="履约时间" sortable :formatter="$moment.sdtFormatter"
                            width="150" />
                        <el-table-column prop="isCompletedName" label="是否完成" width="80" />
                    </el-table>
                </div>

                <div class="form-group-title">线下签约合同附件</div>
                <div class="form-group">
                    <div class="border rounded p-1">
                        <multi-file-uploader v-model="plantContract.signOfflineContractAttachments"
                            accept=".pdf,.jpg,.jpeg,.png" />
                    </div>
                    <div class="text-info mt-2">上传已线下签章生效的PDF、图片附件。</div>
                </div>
            </div>
            <template #footer>
                <span v-if="toDos.length==0" class="text-danger mr-3">未添加履约</span>
                <el-button :disabled="toDos.length==0" type="primary" @click="signPlantContractOffline">确定</el-button>
                <el-button @click="signPlantContractOfflineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="signPlantContractOnlineDialogVisible" title="线上签约" :close-on-click-modal="false"
            width="800px">
            <div style="margin: 0 -16px;">
                <div class="form-group-title">履约</div>
                <div class="form-group">
                    <el-table :data="toDos" border>
                        <el-table-column prop="title" label="履约事件" sortable />
                        <el-table-column prop="reminderTime" label="履约时间" sortable :formatter="$moment.sdtFormatter"
                            width="150" />
                        <el-table-column prop="isCompletedName" label="是否完成" width="80" />
                    </el-table>
                </div>

                <div class="form-group-title">水发公司（甲方）</div>
                <div class="form-group">
                    {{plantContract.companyName}}
                    <span class="text-info">
                        {{plantContract.companySignerName}}
                        （{{plantContract.companySignerMobile}}）
                    </span>
                </div>

                <div class="form-group-title">种植户（乙方）</div>
                <div class="form-group">
                    {{plantContract.farmerName}}
                    <span class="text-info">
                        {{plantContract.farmerSignerName}}
                        （{{plantContract.farmerSignerMobile}}）
                    </span>
                </div>

                <div class="form-group-title">担保人</div>
                <div class="form-group">
                    <div v-for="(warrantor,index) in plantContract.warrantors" :key="index">
                        {{warrantor.receiverName}}
                        （{{warrantor.receiverMobile}}）
                    </div>
                    <el-empty v-if="plantContract.warrantors.length==0" />
                </div>

                <div class="form-group-title">其他签署人</div>
                <div class="form-group">
                    <div v-for="(otherSignatory,index) in plantContract.otherSignatories" :key="index">
                        {{otherSignatory.receiverName}}
                        （{{otherSignatory.receiverMobile}}）
                        {{otherSignatory.companyName}}
                    </div>
                    <el-empty v-if="plantContract.otherSignatories.length==0" />
                </div>
            </div>
            <template #footer>
                <span v-if="toDos.length==0" class="text-danger mr-3">未添加履约</span>
                <el-button :disabled="toDos.length==0" type="primary" @click="signPlantContractOnline">确定</el-button>
                <el-button @click="signPlantContractOnlineDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import ToDos from './components/ToDos.vue';

    export default {
        data() {
            return {
                key: null,
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantContract: {
                    warrantors: [],
                    otherSignatories: [],
                },
                toDos: [],
                toDosDialogVisible: false,
                signPlantContractOfflineDialogVisible: false,
                signPlantContractOnlineDialogVisible: false,
            };
        },
        components: { ToDos },
        methods: {
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 3,
                        key: this.key,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            showToDosDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.toDosDialogVisible = true;
            },
            async loadToDos() {
                this.toDos = [];
                let response = await this.$axios.get('/api/ToDo/GetEventToDos', { params: { eventId: this.plantContract.id } });
                this.toDos = response.data;
            },
            showSignPlantContractOfflineDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.signPlantContractOfflineDialogVisible = true;
                this.loadToDos();
            },
            showSignPlantContractOnlineDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.signPlantContractOnlineDialogVisible = true;
                this.loadToDos();
            },
            async signPlantContractOffline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/SignPlantContractOffline', {
                    id: this.plantContract.id,
                    signOfflineContractAttachments: this.plantContract.signOfflineContractAttachments,
                });
                this.signPlantContractOfflineDialogVisible = false;
                this.loadPlantContracts();
            },
            async signPlantContractOnline() {
                if (!confirm('确定要签约吗？')) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/SignPlantContractOnline', {
                    id: this.plantContract.id,
                });
                this.signPlantContractOnlineDialogVisible = false;
                this.loadPlantContracts();
            },
        },
        created() {
            this.loadPlantContracts();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>